<template>
  <v-btn @click="refresh" v-if="create">
    <span>{{'action.refresh'| t}}</span>
    <v-icon>fa fa-sync-alt</v-icon>
  </v-btn>
</template>

<script lang="babel" type="text/babel">
import formActionMixins from '@/components/form/formAction/formActionMixins'
export default {
  mixins: [formActionMixins],
  methods: {
    refresh() {
      this.$apopup.base({
        title: this.$t(`refresh.confirm`),
        content: this.$t(`refresh.confirm.content`),
        applyCallback: () => this.formActions.initForm(),
      })
    },
  },
  computed: {
    create() {
      if(!this.dataAction) return true
      if(this.dataAction.refresh === false) return false
      return true
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
